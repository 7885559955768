// import TimelineSection from "../TimelineSection";
import cargojet from "../pictures/cargojet.jpg";
import earthfresh from "../pictures/earthfresh.png";

function ExperienceTimeline () {

    return (

        <ul className="experience-timeline timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
        <li>
            <div className="timeline-middle">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5">
                <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd" />
            </svg>
            </div>
            <div className="timeline-start mb-10 md:text-end w-11/12">

                <time className="font-mono italic text-xl">2022-2024</time>

                <div className="font-sans text-lg font-medium">CargoJet Airways</div>

                <div className="font-sans text-xl font-bold mb-2">Ramp Operations Coordinator</div>

                <p className="font-sans text-lg"> 
                    In my role coordinating and leading aircraft ground operations, I was responsible for ensuring saftey, punctual cargo loading, and optimzing the efficiency of my team. This experience honed my ability to lead under pressure, streamline complex logistics, and achieve high-impact results in a high-stakes environment.
                </p>

            <div className="flex flex-col items-end mr-2"> 
                <img src={cargojet} alt="cargojet image" className="w-6/12 mt-5 rounded-xl mb-6"/>
            </div>

            </div>

            <hr className="bg-primary"/>
        </li>


        <li>
            <hr className="bg-primary"/>
            <div className="timeline-middle">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5">
                <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd" />
            </svg>
            </div>
            <div className="timeline-end mb-10 w-11/12">
            <time className="font-mono italic text-xl">2023</time>
            <div className="font-sans text-lg font-medium">EarthFresh Farms</div>

            <div className="font-sans text-xl font-bold mb-2">Brand Ambassador - Team Lead</div>

            <p className="font-sans text-lg"> 
                As Team Lead for EarthFresh Farms, I spearheaded the launch of a new product, generating impressive sales growth that reached up to <a className="font-medium">1700%</a> in select locations. I coordinated a successful promotional campaign, ensuring smooth logistics and timely execution across all touchpoints. My role involved managing and inspiring a team to work cohesively, fostering collaboration and delivering exceptional results. 
            </p>
            <div className="flex flex-col items-start ml-2"> 
                <img src={earthfresh} alt="airstream pic" className="w-6/12 mt-5 rounded-xl mb-6"/>
            </div>

            </div>
            <hr className="bg-primary"/>
        </li>


        <li>
            <hr className="bg-primary"/>
            <div className="timeline-middle">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5">
                <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd" />
            </svg>
            </div>
            <div className="timeline-start mb-10 md:text-end w-11/12">
            <time className="font-mono italic text-xl">2020-2022</time>

                <div className="font-sans text-lg font-medium">Canadian Tire Corp.</div>

                <div className="font-sans text-xl font-bold mb-2">Automotive Parts Advisor</div>

                <p className="font-sans text-lg mb-5"> 
                    I implemented organized inventory management solutions to ensure precise tracking and availability, supporting customer needs with efficiency. In a high-demand, high-pressure environment, I handled a full spectrum of customer service tasks, including real-time inquiries and issue resolution, applying problem-solving skills honed in both technical and logistical domains.
                </p>
            {/* <div className="flex flex-col items-end ml-2"> 
                <img src={cargojet} alt="cargojet image" className="w-5/12 mt-5 rounded-xl mb-6"/>
            </div> */}
            
            </div>
            <hr className="bg-primary"/>
        </li>


        <li>
            <hr className="bg-primary"/>
            <div className="timeline-middle">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5">
                <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd" />
            </svg>
            </div>
            <div className="timeline-end mb-10  w-11/12">
                <time className="font-mono italic text-xl">2021</time>

                <div className="font-sans text-lg font-medium">Ancaster Computer Product</div>

                <div className="font-sans text-xl font-bold mb-2">I.T. Internship</div>

                <p className="font-sans text-lg mb-5"> 
                    During my IT internship at Ancaster Computer Products, I gained hands-on experience with a wide range of technical support tasks, from troubleshooting and repairing hardware issues on customer computers to configuring network systems and optimizing server performance. I honed my skills in diagnosing software and hardware problems, implementing effective solutions, and managing routine maintenance tasks to ensure smooth operation of IT infrastructure.  
                </p>

            </div>
            {/* <hr /> */}
        </li>
        {/* <li>
            <hr />
            <div className="timeline-middle">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5">
                <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd" />
            </svg>
            </div>
            <div className="timeline-start mb-10 md:text-end">
            <time className="font-mono italic">2015</time>
            <div className="text-lg font-black">Apple Watch</div>
            The Apple Watch is a line of smartwatches produced by Apple Inc. It incorporates fitness
            tracking, health-oriented capabilities, and wireless telecommunication, and integrates with
            iOS and other Apple products and services
            </div>
        </li> */}
        </ul>
    )
}

export default ExperienceTimeline;