import mePic from './pictures/portrait-me.png';
import Header1 from './subcomponents/header1';

function Home() {
    return (

        <section id="home">
        <div className="Home min-h-screen lg:min-h-[80vh] flex justify-center items-center text-slate-50 mb-[15%]">
            <div className="HomeMeSection p-7">
                <div className="flex justify-center items-center pb-[1vh]">
                    <img src={mePic} alt="Me" className="mePic max-h-[10vh] md:max-h-[20vh] lg:max-h-[40vh] rounded-full"/>
                </div>
                {/* <div className="flex justify-center p-[1vh]">
                    <h1 className="text-[5vw] md:text-[4vw] lg:text-[3vw] font-medium">Dilawar Amin</h1>
                </div> */}
                <Header1 header="Dilawar Amin" />
                <div className="flex flex-col items-center p-[1vh]">
                    <h2 className="text-xl">Professional Problem Solver.</h2>
                    <h2 className="text-xl">Full-Stack Developer.</h2>
                </div>
            </div>
        </div>
        </section>



    )
}

export default Home;