

function Header1({header}) {

    return (
        <div className="flex justify-center p-[1vh]">
            <h1 className="text-[5vw] md:text-[4vw] lg:text-[3vw] font-medium">{header}</h1>
        </div>
    )
}

export default Header1;