import { Link } from 'react-scroll';

import planetEarth from './pictures/planet-earth.png';

function Navbar() {
    return (
      <nav className="NavBar flex justify-between text-slate-50 max-h-36">

        <div className='nav-bar-icon p-6 ml-4'>
            <img src={planetEarth} alt="Planet Earth" className="planetEarth max-h-14"/>
        </div>

        <div className="navigation-buttons flex justify-normal space-x-5 m-6 mr-12 text-xl">

            <p className='p-0 m-0'><Link                 
                to="home" 
                smooth={true} 
                duration={500}  
                className="inline hover:drop-shadow-lg hover:underline transition duration-500 cursor-pointer p-1">
                Home
            </Link></p>

            <p className='p-0 m-0'><Link                 
                to="projects" 
                smooth={true} 
                duration={500}  
                className="inline hover:drop-shadow-lg hover:underline transition duration-500 cursor-pointer p-1">
                Projects
            </Link></p>

            <p className='p-0 m-0'><Link                 
                to="experience" 
                smooth={true} 
                duration={500}  
                className="inline hover:drop-shadow-lg hover:underline transition duration-500 cursor-pointer p-1">
                Experience
            </Link></p>

            <p className='p-0 m-0'><Link                 
                to="contact" 
                smooth={true} 
                duration={500}  
                className="inline hover:drop-shadow-lg hover:underline transition duration-500 cursor-pointer p-1">
                Contact
            </Link></p>

        </div>  
  
      </nav>
    );
  }
  
  export default Navbar;