import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
// import Stack from '@mui/material/Stack';

import TechButton from './tech-button';


function ProjectCard({ title, description, imageLink, imageAlt, visitLink, techStack}) {

  // const colors = [
  //   '#4A1553', // Base Purple
  //   '#6A1B9A', // Darker Purple
  //   '#8E24AA', // Medium Purple
  //   '#AB47BC', // Lighter Purple
  //   '#D1C4E9', // Light Lavender
  // ];

    return (
        <Card 
        
        // sx={{ minWidth: '95%', maxWidth: 400, backgroundColor: '#1f2937' }}

        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minWidth: '100%',
            maxWidth: '28vw',
            backgroundColor: '#1f2937',
        }}  
        
        >
          <CardMedia
            component="img"
            // height="45vh"
            image={imageLink}
            alt={imageAlt}
            sx={{
                height: '65%',
                objectFit: 'cover',
                objectPosition: 'top',
            }} 
          />
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography gutterBottom variant="h5" component="div" 
                sx={{ 
                    color: '#f8fafc', 
                    fontFamily: 'sans-serif',
                    fontSize: '1.75rem' }}>
              {title}
            </Typography>
            <Typography variant="body2" 
                sx={{ 
                    color: '#f8fafc', 
                    fontFamily: 'sans-serif',
                    fontSize: '1rem',
                    marginBottom: 2,
                    }}>
              {description}
            </Typography>

          <div className='flex flex-row space-x-2 flex-wrap mt-2'>
            {techStack.map((tech, index) => (
              <TechButton 
                tech={tech}
                bgColor="#6A1B9A" />
            ))}
          </div>

          </CardContent>
        <CardActions>
          <Button 
            size="small"
            href={visitLink}
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer"
            sx={{
                color: '#e6b3ff',
                paddingRight: 1,
                paddingLeft: 1,
                fontSize: '1rem',
                ":hover": {background: '#EADCF233',},
            }}
            >
            Source Code
          </Button>
        </CardActions>
      </Card>
    );
  }
    
export default ProjectCard;