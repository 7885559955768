import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

function TechButton( {tech, bgColor}) {

  return (

        <Button 
            variant="contained"
            sx={{
                backgroundColor: bgColor,
                color: '#f8fafc',
                textTransform: 'none',
                fontSize: '1rem',
                fontFamily: 'sans-serif',
                marginBottom: 1,
                paddingRight: 0.75,
                paddingLeft: 0.75,
                paddingTop: 0,
                paddingBottom: 0,
                ":hover": {transform: 'scale(1.1)', transition: 'transform 0.1s',},
              }}
        >
            {tech}
        </Button>
  );
}

export default TechButton;