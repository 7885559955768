import ProjectCard from "./subcomponents/project-card";
import qlearnPic from "./pictures/qlearning-pic.jpg";
import todoPic from "./pictures/todoPic2.jpg";
import ocrPic from "./pictures/ocrPic.jpg";
import Header1 from "./subcomponents/header1";

function Projects() {
    return (

    <section id="projects">
    <div className="Projects min-h-screen lg:min-h-[90vh] mb-[10%]">

    <div className="projectsSection p-7">
        {/* <div className="projectsTitle flex justify-center items-center mt-8">
            <h1 className="text-5xl font-medium">My Projects</h1>
        </div> */}
        <Header1 header="My Projects" />   
        <div className="flex mt-10">
            <div className="ProjectCardsContainer grid-cols-2 flex flex-wrap w-full h-full justify-evenly items-stretch mr-10 ml-10 mt-12">
                
                <div className="w-[580px] h-auto p-2 flex justify-center mb-16">
                    <ProjectCard
                        title={"A.I. Traffic Router"}
                        description={"Machine-learning algorithm that uses Q-learning to compute an optimal path from a starting point to a destination, avoiding areas of traffic congestion."}
                        imageLink={qlearnPic}
                        imageAlt={"Q-learning algorithm"}
                        visitLink={"https://github.com/dilawaramin/Traffic-Management-Machine-Learning"}
                        techStack={["Python", "Q-learning", "Machine Learning", "Matplotlib"]}
                        />
                </div>

                <div className="w-[580px] h-auto p-2 flex justify-center mb-16">
                    <ProjectCard
                    title={"Productivity App"}
                    description={"An elegant productivity manager app that allows users to add, edit, and delete tasks, from any device."}
                    imageLink={todoPic}
                    visitLink={"https://github.com/dilawaramin/Productivity-Application"} 
                    techStack={["React.js", "Node.js", "HTML/CSS", "MongoDB", "NoSQL"]}/>
                </div>

                <div className="w-[580px] h-auto p-2 flex justify-center mb-16">
                    <ProjectCard 
                    title={"Textify PDF"}
                    description={"A web application that converts images and pdfs that contain  text into a PDF file, with indexed and highlightable text, using Optical Character Recognition (OCR)."}
                    imageLink={ocrPic}
                    visitLink={"https://github.com/dilawaramin/Textify-PDF"}
                    techStack={["React.js", "Python", "Tailwind CSS", "Flask", "MySQL", ]}
                    />
                </div>

                {/* <div className="w-1/3 p-4 flex justify-center mb-16">
                    <ProjectCard />
                </div> */}

            </div>
        </div>

    </div>

    </div>
    </section>

    );
  }
  
  export default Projects;