import Header1 from "./subcomponents/header1";
import Socials from "./subcomponents/Socials";


function Contact() {
    return (

    <section id="contact">

    <div className="Experience h-[85vh] flex flex-col items-center pt-12">
        <div className="experience-header mb-8">
            <Header1 header="Get in Touch!" />
        </div>
    <div className="flex flex-row w-[90%] justify-center mb-12">
        <p className="font-sans text-xl font-medium">
            Ready to turn ideas into action? Whether it's code or conversation, I'm here for both. Reach out, and let's build something amazing together!
        </p>
    </div>

        <div className="SocialSection w-[95vw]">
            <Socials />
        </div>

    </div>
    </section>

    );
  }
  
  export default Contact;