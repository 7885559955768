import Header1 from "./subcomponents/header1";
import ExperienceTimeline from "./subcomponents/ExperienceTimeline";


function Experience() {
    return (
    <section id="experience">

    <div className="Experience mb-72">
        <div className="experience-header mb-8">
            <Header1 header="My Work Experience" />
        </div>
        <div className="p-10">
            <ExperienceTimeline />
        </div>

    </div>
    </section>

    );
  }
  
  export default Experience;