import Navbar from './components/Navbar';
import Home from './components/Home';
import Projects from './components/Projects';
import Experience from './components/Experience';
import Contact from './components/Contact';
import BackToTop from './components/BackToTop';

function App() {
  return (
    <div className="App text-slate-50 scroll-smooth">
      <div className="fixed inset-0 -z-10 h-full w-full items-center px-5 py-24 [background:radial-gradient(125%_125%_at_50%_20%,#000_40%,#63e_100%)]">
      </div>

      <Navbar/>
      <Home/>
      <Projects/>
      <Experience/>
      <Contact/>
      <BackToTop/>

    </div>
  );
}

export default App;
