import { React } from 'react';
import { FaLinkedin, FaGithub, FaInstagram, FaTwitter } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';


function Socials () {

    return (

<div className="flex justify-center w-full accent-slate-50">

    <a className="btn btn-outline text-slate-50 hover:bg-slate-50 p-6 h-auto"
        href="https://www.linkedin.com/in/dilawar-amin-70a39719b/" 
        target="_blank" 
        rel="noopener noreferrer">
            <FaLinkedin 
                size={40}
            />
    </a>

    <div className="divider divider-horizontal divider-primary"></div>

    <a className="btn btn-outline text-slate-50 hover:bg-slate-50 p-6 h-auto"
        href="https://github.com/dilawaramin" 
        target="_blank" 
        rel="noopener noreferrer">
            <FaGithub 
                size={40}
            />
    </a>

    <div className="divider divider-horizontal divider-primary"></div>

    <a className="btn btn-outline text-slate-50 hover:bg-slate-50 btn- p-6 h-auto"
        href="mailto:contact@dilawar.io" 
        target="_blank" 
        rel="noopener noreferrer">
            <HiOutlineMail 
                size={40}
            />
    </a>


</div>

    )
}

export default Socials;